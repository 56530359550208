import "./our-team.css";
import React from "react";
import image65 from "../../images/women 65.jpeg";
import image67 from "../../images/women 67.jpg";
import image68 from "../../images/women 68.jpg";
import image69 from "../../images/women 69.jpg";
import image70 from "../../images/women 70.jpg";
import image71 from "../../images/women 71.jpg";
import image72 from "../../images/women 72.jpg";
import image73 from "../../images/women 73.jpg";
import image74 from "../../images/women 74.webp";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";


function OurTeam() {
    return (
      <section id="team" className="team">
        <div className="container" data-aos="fade-up">

          <div className="chefs-section-title">
            {/* <h2>Our Team</h2> */}
            <p>Our Professional Team</p>
          </div>

          <div className="row">

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <img src={image72} className="img-fluid" alt="..."/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Audrey Mugeni</h4>
                    <span>Program Co-Ordinator</span>
                  </div>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/audrey-mugeni-m/" target="_blank" rel="noreferrer" className="linkedin"><i className="bi bi-linkedin"></i><FaLinkedin/></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <img src={image65} className="img-fluid" alt="..."/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Angela Langat</h4>
                    <span>Volunteer</span>
                  </div>
                  <div className="social">
                    <a href="https://twitter.com/AngelaLangat2" target="_blank" rel="noreferrer" className="twitter"><i className="bi bi-twitter"></i><FaTwitter/></a>
                    <a href="https://www.facebook.com/cher0tuga" target="_blank" rel="noreferrer" className="facebook"><i className="bi bi-facebook"></i><FaFacebook/></a>
                    <a href="https://www.instagram.com/chero.tuga/" target="_blank" rel="noreferrer" className="instagram"><i className="bi bi-instagram"></i><FaInstagram/></a>
                    <a href="https://www.linkedin.com/in/angela-langat/" target="_blank" rel="noreferrer" className="linkedin"><i className="bi bi-linkedin"></i><FaLinkedin/></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <img src={image69} className="img-fluid" alt="..."/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Amanya Yahuma</h4>
                    <span>Volunteer</span>
                  </div>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/zawadi-a-5702b4228/" target="_blank" rel="noreferrer" className="linkedin"><i className="bi bi-linkedin"></i><FaLinkedin/></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <img src={image74} className="img-fluid" alt="..."/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Lynnette Kawira (Meme)</h4>
                    <span>Volunteer</span>
                  </div>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/lynnet-kawira-meme-21a5b0230/" target="_blank" rel="noreferrer" className="linkedin"><i className="bi bi-linkedin"></i><FaLinkedin/></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <img src={image70} className="img-fluid" alt="..."/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Scholastica Kaaria </h4>
                    <span>Board Chair</span>
                  </div>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/scholar-kaaria-143552170/" target="_blank" rel="noreferrer" className="linkedin"><i className="bi bi-linkedin"></i><FaLinkedin/></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <img src={image71} className="img-fluid" alt="..."/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Janet Keru </h4>
                    <span>Board Member</span>
                  </div>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/janet-keru-a7911834/" target="_blank" rel="noreferrer" className="linkedin"><i className="bi bi-linkedin"></i><FaLinkedin/></a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 d-flex align-items-stretch">
              <div className="member" data-aos="zoom-in" data-aos-delay="100">
                <img src={image73} className="img-fluid" alt="..."/>
                <div className="member-info">
                  <div className="member-info-content">
                    <h4>Hellen Okoth Owiti</h4>
                    <span>Board Treasurer</span>
                  </div>
                  <div className="social">
                    <a href="https://www.linkedin.com/in/hellen-okoth-owiti-ma-llb-31588119/" target="_blank" rel="noreferrer" className="linkedin"><i className="bi bi-linkedin"></i><FaLinkedin/></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default OurTeam;