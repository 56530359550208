import React from "react";
import './how.css';
import image51 from "../../images/women 51 .jpg";
import image52 from "../../images/women 52 .jpg";
import image55 from "../../images/women 55 .jpg";
import image56 from "../../images/women 56 .jpg";

function How () {
    return (
      <>

        <section className="how-section">
          <div className="how-image-container">
            <h1><strong>HOW IT <span>ALL STARTED</span></strong></h1>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-1 order-lg-1 hero-img aos-init aos-animate" data-aos="fade-right" data-aos-delay="200" style={{marginTop: "65px"}}>
                <img src={image51} className="img-fluid" alt="..."/>
              </div>

              <div className="col-lg-6 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100" style={{marginTop: "64px"}}>
                <p>
                    2018 was a bewildering year, a time when the fog seemed to lift from our collective gaze, revealing a
                    stark reality. It was a year marred by the loss of women, and distressingly, the blame for these tragedies
                    was unjustly placed upon them. Two heart-wrenching deaths, in particular, cast a shadow over our
                    consciousness: that of Sharon Otieno, allegedly taken by the then Governor of her hometown Migori
                    County, and Monica Kimani, whose life was brutally cut short while she lay in her bathtub, her throat
                    mercilessly slit open. As we shared these stories amongst ourselves, it became painfully evident that
                    these women were not only robbed of their lives but also condemned and demonized for the way they
                    chose to live. In the midst of these conversations, we stumbled upon news of yet another woman&#39;s
                    passing, a life extinguished silently, absent from the public eye. In solemn reflection, we raised our
                    glasses in homage to the departed, pondering the question that hung heavy in the air: What can we do?
                </p>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-1 order-lg-1 d-flex flex-column justify-content-center aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                <p>
                  December of 2018 marked a turning point, as January of the following year saw the inception of our
                  plan. The Counting Dead Women page emerged on Facebook, its purpose clear—to compile a somber
                  tally of lives lost to violence. We began by meticulously gathering the names of twenty girls and women
                  who fell victim to femicide in January alone. This modest undertaking ignited a journey that entailed
                  scouring newspapers late into the night, and intently listening to national news agencies, ever vigilant
                  for reports of femicides. For five long years, we faithfully recorded these tragic incidents, driven by a
                  deep yearning to expose the scale of the crisis.
                </p>
              </div>
              
              <div className="col-lg-6 order-2 order-lg-2 hero-img aos-init aos-animate" data-aos="fade-left" data-aos-delay="200">
                <img src={image52} className="img-fluid" alt="..."/>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-1 order-lg-2 d-flex flex-column justify-content-center aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                <p>
                    And now, we find ourselves at a juncture where questions once again beckon us: What lies beyond mere
                    counting? In response, Femicide Count - Kenya came into existence in December of 2022, resolute in its
                    commitment to safeguarding, research, and capacity building. Our focus has shifted from merely
                    documenting the lives lost to taking concrete actions that protect and empower women and girls.                In pursuit of safeguarding, we endeavor to create an environment where women are shielded from
                    harm, both physical and emotional. Through research, we seek to unravel the intricate layers that
                    contribute to the perpetuation of violence, thereby illuminating pathways to lasting change.
                </p>
              </div>

              <div className="col-lg-6 order-1 order-lg-1 hero-img aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                <img src={image55} className="img-fluid" alt="..."/>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-6 order-1 order-lg-2 hero-img aos-init aos-animate" data-aos="fade-left" data-aos-delay="200">
                <img src={image56} className="img-fluid" alt="..."/>
              </div>

              <div className="col-lg-6 pt-lg-0 order-1 order-lg-1 d-flex flex-column justify-content-center aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                <p>
                    Moreover,we dedicate ourselves to building the capacities of individuals and institutions, nurturing a collective
                    strength that can challenge the prevailing narrative and dismantle the structures that enable such
                    atrocities to persist. Our journey is far from over, and the road ahead may be arduous. Yet, driven by a shared vision of
                    justice and equality, we press onward. Together, we aim to transform the discourse surrounding
                    femicide, weaving threads of empathy, understanding, and compassion into the fabric of society.
                </p>
              </div>
            </div>
          </div>
          {/* <div className="how-texts">
            <h1><strong> HOW IT ALL STARTED<span>.</span></strong></h1>

            <p>

            </p>

            <p>

            </p>

            <p>

            </p>

            <p>

            </p>

            <p>

            </p>
          </div> */}
        </section>
      </>
    )
}

export default How;