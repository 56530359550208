import './App.css';
import NavBar from './components/navbar';
import { Route, Routes } from 'react-router-dom';
import Home from './components/home';
import Donate from './components/donate';
import Count from './components/count';
import Contact from './components/contact';
import Footer from './components/footer';
import How from './components/how';
import Mission from './components/mission';
import Values from './components/values';
import OurTeam from './components/our-team';
import Partners from './components/partners';
import Volunteers from './components/volunteers';
import Services from './components/services';
import Internships from './components/internships';
import Blog from './components/blog';
import Catalogue from './components/catalogue';
import Singlepost from './components/singlepost';
import Research from './components/research';
import Advocacy from './components/advocacy';
import Capacity from './components/capacity';
import Safeguarding from './components/safeguarding';
import Strategic from './components/strategic';
import Login from './components/login';
import Legal from './components/legal';
import SinglePost1 from './components/singlepost1/singlepost1';
import BackendForm from './components/backendform';
import GetStarted from './components/get-started';


function App() {
  return (
    <div className="App">
      <NavBar/>

      <Routes>
        <Route path="/home" element={<Home/>}/>
        <Route path="/" element={<GetStarted/>}/>
        <Route path="/donate" element={<Donate/>}/>
        <Route path="/count" element={<Count/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/how-it-started" element={<How/>}/>
        <Route path="/mission&vision" element={<Mission/>}/>
        <Route path="/login" element={<Login/>}/>
        <Route path="/singlepost1" element={<SinglePost1/>}/>
        <Route path="/values" element={<Values/>}/>
        <Route path="/our-team" element={<OurTeam/>}/>
        <Route path="/partners" element={<Partners/>}/>
        <Route path="/volunteers" element={<Volunteers/>}/>
        <Route path="/work" element={<Services/>}/>
        <Route path="/internships" element={<Internships/>}/>
        <Route path="/blog" element={<Blog/>}/>
        <Route path="/catalogued-cases" element={<Catalogue/>}/>
        <Route path="/singlepost" element={<Singlepost/>}/>
        <Route path="/research" element={<Research/>}/>
        <Route path="/advocacy" element={<Advocacy/>}/>
        <Route path="/capacity-building" element={<Capacity/>}/>
        <Route path="/safeguarding" element={<Safeguarding/>}/>
        <Route path="/strategic-plan" element={<Strategic/>}/>
        <Route path="/legal-framework" element={<Legal/>}/>
        <Route path="/backendform" element={<BackendForm/>}/>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
