import React from "react";
import "./safeguarding.css";
import { FaCheckCircle } from "react-icons/fa";

function Safeguarding () {
    return (
        <>

        <section className="safeguarding-section">
            <div className="safeguarding-image-container">
                <h1><strong>SAFEGU<span>ARDING</span></strong></h1>
            </div>

            <div className="safeguarding-texts aos-init aos-animate" data-aos="fade-in" data-delay="100">
                {/* <h1><strong>Safeguarding</strong></h1> */}
            </div>

            <p>
                When responding to a crisis, running sexual reproductive health programmes, or advocating for
                the prevention and ending of femicides across the country and region, safeguarding children
                and vulnerable adults is our first priority. We want to ensure that everyone who comes into
                contact with Femicide Count-Kenya is Kenya.
            </p>
            
            <p>
                We are committed to ensure that children and vulnerable adults are protected from both
                deliberate and accidental harm and abuse caused by staff and other reps.
            </p>

            <p>
                The key to safeguarding is prevention and making reporting concerns as easy as possible for
                children, parents, community members and staff. Our reporting strategies are diverse, and are
                often developed with children and the community to ensure that they work for the particular
                setting, program and culture.
            </p>

            <p>
                Listen to children and adults in the communities we work in, and build in child and adult
                participation and feedback into all of our programming and advocacy work.
            </p>

            <p>
                Steps taken to undertake safeguarding;
            </p>

            <div className="content aos-init aos-animate" data-aos="zoom-in" data-delay="200">
                    <ul>
                        <li><i className="bx bx-check-double"><FaCheckCircle/></i>&nbsp;
                            Make children, their families, communities and our staff aware of the standards of
                            behaviour they can expect from us and of how they can raise a concern.
                        </li>
                        <li><i className="bx bx-check-double"><FaCheckCircle/></i>&nbsp;
                            Proactively promote and maintain clear, accessible and anonymous reporting
                            mechanisms, including for those with disabilities.
                        </li>
                        <li><i className="bx bx-check-double"><FaCheckCircle/></i>&nbsp;
                            Complete criminal records checks and referencing for all staff during recruitment.
                        </li>
                        <li><i className="bx bx-check-double"><FaCheckCircle/></i>&nbsp;
                            In-depth safeguarding induction and refresher training for staff, at least once every two
                            years.
                        </li>
                        <li><i className="bx bx-check-double"><FaCheckCircle/></i>&nbsp;
                            Ensure all representatives are aware of the risks of child and adult abuse, their
                            safeguarding responsibilities, and the requirement to report.
                        </li>
                        <li><i className="bx bx-check-double"><FaCheckCircle/></i>&nbsp;
                            Risk assess all activities and adhere to robust mitigation plans.
                        </li>
                        <li><i className="bx bx-check-double"><FaCheckCircle/></i>&nbsp;
                            Embed 'safer programming'; steps throughout our programme development and
                            implementation.
                        </li>
                        <li><i className="bx bx-check-double"><FaCheckCircle/></i>&nbsp;
                            Limit the opportunities for staff, volunteers and partners to abuse children, for example,
                            by ensuring that no child or children are left alone with a staff member.
                        </li>
                        <li><i className="bx bx-check-double"><FaCheckCircle/></i>&nbsp;
                            Take all concerns seriously, and respond rapidly in a survivor-focused way.
                        </li>
                        <li><i className="bx bx-check-double"><FaCheckCircle/></i>&nbsp;
                            Learn lessons from all incidents, and ensure learning is implemented.
                        </li>
                        <li><i className="bx bx-check-double"><FaCheckCircle/></i>&nbsp;
                            Report all serious concerns to donors as appropriate.
                        </li>
                        <li><i className="bx bx-check-double"><FaCheckCircle/></i>&nbsp;
                            Provide safeguarding capacity building to organizations working with children and
                            vulnerable adults.
                        </li>
                        <li><i className="bx bx-check-double"><FaCheckCircle/></i>&nbsp;
                            Providing education opportunities to vulnerable girls.
                        </li>
                    </ul>
            </div>
            


        </section>
        </>
    )
}

export default Safeguarding;