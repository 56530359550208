import "./navbar.css";
import Aos from "aos";
import "aos/dist/aos.css";
import React from 'react';
import { NavLink } from 'react-router-dom';

function NavBar() {

  Aos.init();

  const menu = () => {
    const menuBox = document.getElementById('menu-box')
    const navLinks = document.querySelector('.nav-links')
    menuBox.classList.toggle('clicked')
    navLinks.classList.toggle('show')
  }
  return (

    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <nav>
          <div className="left-nav color-changer">
            <p><NavLink to="/">Femicide Count Kenya</NavLink></p>
          </div>

          <div className="right-nav">
            <div className="nav-links">
              <ul>
                <li className="dropdown"><span>Who We Are</span>
                  <ul>
                    <li><NavLink to="/how-it-started">How it Started</NavLink></li>
                    <li><NavLink to="/mission&vision">Mission & Vision</NavLink></li>
                    <li><NavLink to="/values">Values</NavLink></li>
                    <li><NavLink to="/our-team">Our Team</NavLink></li>
                    <li><NavLink to="/partners">Partners</NavLink></li>
                  </ul>
                </li>

                <li className="dropdown"><span>What We Do</span> 
                  <ul>
                    <li><NavLink to="/research">Research</NavLink></li>
                    <li><NavLink to="/capacity-building">Capacity Building</NavLink></li>
                    <li><NavLink to="/safeguarding">Safeguarding</NavLink></li>
                  </ul>
                </li>

                <li className="dropdown"><span>Resources</span> 
                  <ul>
                    <li><NavLink to="/strategic-plan">Strategic Plan</NavLink></li>
                    <li><NavLink to="/catalogued-cases">Catalogued Cases</NavLink></li>
                    <li><NavLink to="/blog">Blog</NavLink></li>
                  </ul>
                </li>

                <li className="dropdown"><span>Get In Touch</span>
                  <ul>
                    <li><NavLink to="/contact">Contact Us</NavLink></li>
                    <li><NavLink to="/volunteers">Volunteers</NavLink></li>
                  </ul>
                </li>

                <li className="dropdown color-changer"><NavLink to="/count"><span className="color-changer">Count With Us</span></NavLink></li>
              </ul>
            </div>

            <div id="menu-box" onClick={menu}>
              <span className="bar"></span>
              <span className="bar"></span>
              <span className="bar"></span>
            </div>
          </div>
        </nav>
      </header> 
    </>
  );
}

export default NavBar;