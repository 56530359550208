import "./partners.css";
import image30 from "../../images/women 30 .png";
import image31 from "../../images/women 31 .png";
import image32 from "../../images/women 32 .png";
import image44 from "../../images/women 44 .jpg";
import image45 from "../../images/women 45 .jpg";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";


function Partners () {
    return (
        <>
            <section className="partners-section">
                
                <div className="image-container">
                    <h1><strong>Our <span>Partners</span></strong></h1>
                </div>

                <div className="container partners-texts" style={{marginTop: "95px"}}>
                    <div className="row">
                        <div className="col-lg-6 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                            <p>
                                At Femicide Count- Kenya, we believe that partnerships are critical to achieving our goal of
                                promoting and protecting human rights in Kenya. We recognize that no single organization or
                                actor can achieve meaningful change alone. That&#39;s why we actively seek out collaborations and
                                partnerships with other organizations, government agencies, and stakeholders.
                                Our partnership strategy is based on a collaborative approach that focuses on building
                                relationships, sharing knowledge and resources, and working together towards common goals.
                                We prioritize partnerships that are strategic, mutually beneficial, and align with our mission and
                                values.
                            </p>
                        </div>

                        <div className="col-lg-6 order-1 order-lg-2 hero-img aos-init aos-animate" data-aos="fade-left" data-aos-delay="200">
                            <img src={image44} className="img-fluid" alt="..."/>
                        </div>
                    </div>
                </div>

                <div className="container partners-texts">
                    <div className="row">
                        <div className="col-lg-6 pt-lg-0 order-1 order-lg-2 d-flex flex-column justify-content-center aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                            <p>
                                Through our partnerships, we aim to amplify our impact, leverage our resources, and achieve
                                greater scale and sustainability in our programs and advocacy efforts. We collaborate with
                                partners on a range of initiatives, from research and capacity-building programs to education
                                support and advocacy campaigns.
                                We also recognize that partnerships require ongoing investment and attention. That&#39;s why we
                                prioritize open and transparent communication, regular monitoring and evaluation, and mutual
                                accountability in all our partnerships.
                            </p>

                            <p>
                                We believe that these elements are critical to building
                                strong and lasting partnerships that can create meaningful change.
                                If you&#39;re interested in partnering with us, we&#39;d love to hear from you. Please reach out to us
                                through our website or email, and let&#39;s work together towards a more just and equitable Kenya.
                            </p>
                        </div>

                        <div className="col-lg-6 order-1 order-lg-1 hero-img aos-init aos-animate" data-aos="fade-left" data-aos-delay="200">
                            <img src={image45} className="img-fluid" alt="..."/>
                        </div>
                    </div>
                </div>
            </section>


            <section id="testimonials" className="testimonials section-bg">
                <div className="container">
                    <div className="section-title aos-init aos-animate" data-aos="fade-up">
                        <h2>Other Femicide Count Resources</h2>
                        <p>
                            Here are some of the other sites/ social media pages, globally, that keep track of Femicide cases
                        </p>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 d-flex align-items strech aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={image30} className="testimonial-img img-fluid" alt="..."/>
                                        <h3>UTS Social Impact</h3>
                                        <h4>
                                            <a href="https://socialimpact.uts.edu.au/case-study/counting-dead-women-hard-truth-australias-domestic-violence-victims/" target="_blank" rel="noopenner noreferrer">Counting Dead Women (AU)</a>
                                        </h4>
                                        <p>
                                                <i className="bx bxs-quote-alt-left quote-icon-left"><FaQuoteLeft/></i>
                                                It is easy to underestimate the scale of domestic violence in Australia: the communities affected and the lives destroyed. Destroy The Joint has been able to raise awareness in a significant way by reporting the cold hard facts about domestic violence, through its Counting Dead Women campaign.
                                                <i className="bx bxs-quote-alt-right quote-icon-right"><FaQuoteRight/></i> 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items strech aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={image31} className="testimonial-img img-fluid" alt="..."/>
                                        <h3>Keep the Energy</h3>
                                        <h4>
                                            <a href="https://www.instagram.com/keeptheenergy/" target="_blank" rel="noopenner noreferrer">Nonprofit organization(SA)</a>
                                        </h4>
                                        <p>
                                                <i className="bx bxs-quote-alt-left quote-icon-left"><FaQuoteLeft/></i>
                                                    Our aim is to fight gender-based violence against women, children and LGBTQIA+ persons. We cannot afford to die down.
                                                <i className="bx bxs-quote-alt-right quote-icon-right"><FaQuoteRight/></i> 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items strech aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        {/* <img src={imgSrc} className="testimonial-img img-fluid" alt="..."/> */}
                                        <h3>Counting Dead Women(SA)</h3>
                                        <h4>
                                            <a href="https://kareningalasmith.com/counting-dead-women/" target="_blank" rel="noopenner noreferrer">Karen Ingala Smith(UK)</a>
                                        </h4>
                                        <p>
                                                <i className="bx bxs-quote-alt-left quote-icon-left"><FaQuoteLeft/></i>

                                                <i className="bx bxs-quote-alt-right quote-icon-right"><FaQuoteRight/></i> 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 d-flex align-items strech aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={image32} className="testimonial-img img-fluid" alt="..."/>
                                        <h3>Gender Equality Observatory</h3>
                                        <h4>
                                            <a href="https://oig.cepal.org/en" target="_blank" rel="noopenner noreferrer">Latin American and the Caribbean</a>
                                        </h4>
                                        <p>
                                                <i className="bx bxs-quote-alt-left quote-icon-left"><FaQuoteLeft/></i>
                                                    .
                                                <i className="bx bxs-quote-alt-right quote-icon-right"><FaQuoteRight/></i> 
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}


export default Partners;