import React from "react";
import "./legal.css";

function Legal () {
    return (
        <>

        <section className="legal-section">

            <div className="legal-image-container">
                <h1><strong>LEGAL <span>FRAMEWORK</span></strong></h1>
            </div>

            <div className="legal-texts">
                <p>
                    Femicide, the intentional killing of women and girls because of their gender, is a serious human
                    rights violation that undermines gender equality and threatens the lives and well-being of
                    women and girls in Kenya. The legal framework in Kenya provides for the protection of women&#39;s
                    rights, including measures to prevent and punish femicide.
                </p>

                <p>
                    Under Kenyan law, femicide is considered a form of homicide and is punishable under the Penal
                    Code. The Penal Code provides for the punishment of murder and manslaughter, and the
                    Criminal Procedure Code sets out the procedure for investigating and prosecuting cases of
                    homicide, including femicide.
                </p>

                <p>
                    In addition, Kenya has several laws and policies that aim to promote gender equality and protect
                    women&#39;s rights. These include the Constitution of Kenya, which guarantees equal rights and
                    freedoms for men and women, the Sexual Offences Act, which criminalizes sexual violence and
                    harassment, and the Protection Against Domestic Violence Act, which provides for the
                    protection and compensation of victims of domestic violence.
                </p>

                <p>
                    However, despite these legal provisions, femicide remains a serious problem in Kenya. More
                    needs to be done to ensure effective implementation and enforcement of the laws and policies,
                    as well as to address the underlying social, cultural, and economic factors that contribute to
                    femicide.
                </p>

                <p>
                    Our organization is committed to promoting the legal framework for femicide prevention and
                    advocating for better enforcement of laws and policies that protect women&#39;s rights in Kenya. We
                    also support research and data collection on femicide to inform evidence-based policies and
                    interventions that can effectively address this issue.
                </p>
            </div>
        </section>
        </>
    )
}

export default Legal;