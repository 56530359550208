import React from "react";
import "./singlepost.css";
import image42 from "../../images/women 42 .jpg";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Singlepost() {
  const navigate = useNavigate();

  // Make the function asynchronous
  const handleBack = async () => {
    // Simulate an asynchronous operation using a timeout
    await new Promise(resolve => setTimeout(resolve, 1000));
    navigate(-1);
  };

  return (
    <section id="blog" className="blog">
      <button className="back-btn" onClick={handleBack}>
        <i href="#back" className="bx bx-back">
          <FaAngleLeft />
        </i>
      </button>
      {/* The rest of the component code remains unchanged */}
      <div className="container" data-aos="fade-up">
                <div className="row">
                  <div className="col-lg-8 entries">
                    <article className="entry entry-single">
                      <div className="entry-img">
                        <img src={image42} alt="young-girls" className="img-fluid"/>
                      </div>

                      <h2 className="entry-title">
                        <a href="#title">TO BE A TEENAGER</a>
                      </h2>

                      <div className="entry-meta">
                        <ul>
                          <li className="d-flex align-items-center"><i className="bi bi-person"></i><a href="#author">Audrey Mugeni</a></li>
                          <li className="d-flex align-items-center"><i className="bi bi-clock"></i><a href="#time"><time datetime="2023-01-01">Jan 1, 2020</time></a></li>
                          <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i><a href="#comments">Comments</a></li>
                        </ul>
                      </div>

                      <div className="entry-content">
                        <p>
                          Most of us know that period when you are too old to be with the children and too young to be with adults. That period is commonly known as teenage hood. What is it about? Especially here in Kenya.
                        </p>

                        <p>
                          Well, there is school. Teenage years in Kenya, begin at the same time as high school years. High school in Kenya is almost always boarding school and is almost always a school that is far away from home, in a community that you may have never associated with before. So, what is this school about? For starters there is its location: the school may be located in an area known for its cold climate which may be extremely disadvantageous especially if you’re a girl because that means that your uniform is a skirt made from sheer fabric that doesn’t even protect the areas that it covers from the cold leave alone those parts that live forever uncovered.
                        </p>

                        <p>
                          Then, there is the food! Food of subpar quality, made in indescribable conditions, served in rations to cater to a large number of students, at specified hours of the day. It is in these schools where the teachers, who are supposed to be your temporary guardians while you’re there, are almost always the biggest bullies: always picking on you for having basic things like an opinion that is different from theirs. Opinions such as disagreeing with rules that shouldn’t be rules for example; “you can’t wear trousers in school if you are female because it may distract the male teachers.” However, don’t let it sound so grim because as adults some of our fondest memories come from this place believe it or not. Memories are as simple and basic as the friendships we made at this time.
                        </p>

                        <p>
                          Friendships are a major aspect of teenagehood. An aspect not considered often except for that common statement we all heard as teenagers, ”These friends you have now, you will leave them here!” how true is that statement? I’m not quite sure. It is with these friends that we begin to see the world in a new light. We begin to discover emotions, finances, goals, passions, dreams, careers, hobbies, and even basic simple joy and learn about pain and sorrow too. We start to learn to talk about things. Things that many a time we wouldn’t talk about with other people because they “just wouldn’t get it.” 
                        </p>

                        <p>
                          Then there is that important part of our teenage years that we need, love, but not always like. The Adults! And they are a lot! Parents, Teachers, Aunties and Uncles, Religious Leaders, Some friends, and even Mentors. These people shape our teenage years and affect us in some of the strongest ways possible. Good and bad. Not just cause of the pressure. 
                        </p>

                        <p>
                          But, teenagehood is not just about the people in your life during your teenage years or about the school, teenagehood is that time when your childish innocence is lost, “when your eyes are opened to a new light.” It is a time when dreams are discovered and talents are natured. Basically, these are the years that form our futures. It is not a trial and error, however, because once you are done you can never go back rather a corridor to your future.
                        </p>
                      </div>
                    </article>

                    <div className="reply-form">
                      <h4>Leave a reply</h4>
                      <p>Your email address will not be published. Required fields are marked *</p>
                      <form action="">
                        <div className="row">
                          <div className="col-md-6 form-group">
                            <input name="name" type="text" className="form-control" placeholder="Your Name*" required/>
                          </div>
                          <div className="col-md-6 form-group">
                            <input name="email" type="text" className="form-control" placeholder="Your Email*" required/>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col form-group">
                            <input name="website" type="text" className="form-control" placeholder="Your Website"/>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col form-group">
                            <textarea name="comment" className="form-control" placeholder="Your Comment*"></textarea>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Post Comment</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
    </section>
  );
}

export default Singlepost;