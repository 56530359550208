import React from "react";
import "./capacity.css";


function Capacity () {
    return (

        <>
        <section className="capacity-section">

            <div className="capacity-image-container">
                <h1><strong>CAPACITY <span>BUILDING</span></strong></h1>
            </div>

            <div className="capacity-texts">
                <h2><strong>CAPACITY BUILDING</strong></h2>
            </div>
        </section>
        </>
    )
}

export default Capacity;