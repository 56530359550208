import React from "react";
import "./internships.css";

function Internships () {
    return (
        <>

        <section className="internship-section">

            <div className="internship-image-container">
                <h1><strong>INTERNSHIP</strong></h1>
            </div>

            <div className="internship-texts">
                <h2><strong>INTERNSHIP PRPOGRAM<span>.</span></strong></h2>

                <p>
                    We are so glad to have had you as our intern and hope that the journey will now be smoother for you. We are confident that you will not only survive but thrive as a professional in your field.
                </p>
            </div>

            <div className="col-lg-8 mt-5 mt-lg-0">
              <form action="forms/contact.php" method="post" role="form" className="php-email-form">
              <h4><strong>Apply with us.</strong></h4>
                <div className="row">
                  <div className="col-md-6 form-group">
                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                  </div>
                  <div className="col-md-6 form-group">
                    <input type="number" name="number" className="form-control" id="number" placeholder="Your Number" required/>
                  </div>
                  <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                  </div>
                  <div className="col-md-6 form-group">
                    <input type="text" name="location" className="form-control" id="school" placeholder="School" required/>
                  </div>
                </div>
                <div className="form-group mt-3">
                  <textarea className="form-control" name="message" rows="5" placeholder="Message" maxLength={150} required></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <div className="text-center"><button type="submit">Send Message</button></div>
              </form>
            </div>   
        </section>
        </>
    )
}

export default Internships;