import React from "react";
import image13 from "../../images/women 13.png";
import "./values.css";


function Values () {
    return (
        <>

            <section id="values-section">
                <div className="values-image-container">
                    <img src={image13} style={{height: "687px", width: "667px", margin: "left", opacity: "0.29"}} alt="..."/>
                        <div className="values-texts">
                            <h1><strong>Our <span>Values</span></strong></h1>
                        <ul>
                            <li>
                                <span>Respect for human rights:</span>&nbsp;
                                 We are committed to upholding and promoting the principles of human rights, including the right to life, education, and health.
                            </li>
                            <li>
                                <span>Equality and justice:</span>&nbsp;
                                We strive to promote equality and justice for all, regardless of gender, ethnicity, religion, or any other characteristic.
                            </li>
                            <li>
                                <span>Empathy and compassion:</span>&nbsp;
                                We are empathetic and compassionate towards those who have suffered from human rights violations.
                            </li>
                            <li>
                                <span>Collaboration and partnerships:</span>&nbsp;
                                We strive to work collaboratively with other organizations, government agencies, and stakeholders to achieve its mission and goal.
                            </li>
                            <li>
                                <span>Innovation and creativity:</span>&nbsp;
                                We are innovative and creative in our approach to addressing human rights issues in Kenya, and are continuously exploring new ideas and strategies.
                            </li>
                            <li>
                                <span>Integrity and ethics:</span>&nbsp;
                                We operate with integrity and uphold ethical standards in all our activities, including our interactions with donors, partners, and beneficiaries.
                            </li>
                        </ul>
                    </div>
                </div>
            </section>    
        </>
    )
}

export default Values;