import Aos from "aos";
import "./catalogue.css";
import "aos/dist/aos.css";
// import image1 from "../../images/women 1.jpg";
import image34 from "../../images/women 34.png";
import image35 from "../../images/women 35.png";
import image38 from "../../images/women 38 .jpg";
import image40 from "../../images/women 40 .jpg";
import image58 from "../../images/women 58 .jpg";
import image66 from "../../images/women 66.jpg";
import React, { useEffect, useState } from "react";
import { FaBlogger, FaMoneyBillWaveAlt, FaTrophy, FaPlus, FaLink, FaAssistiveListeningSystems, } from "react-icons/fa";

function Catalogue() {
  const [casesData, setCasesData] = useState([]);
//   const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch your cases data asynchronously
    const fetchData = async () => {
      try {
        const response = await fetch('http://localhost:8082/tbl_victims');
        // console.log(response)
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // console.log(data)
        setCasesData(data);
        // console.log(casesData)
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // setLoading(false);
      }
    };

    fetchData(); // Call the async function to fetch data

    // Initialize AOS once data is fetched (if needed)
    Aos.init();

    // axios.get('http://localhost:8082/tbl_victims')
    // .then((response) => {
    //     setCasesData(response.data);
    // })
    // .catch((error) => {
    //     console.error(error);
    // })
  }, []);

  function averageDeaths() {
    fetch('http://localhost:8082/tbl_victims')
    .then(response => response.json())
    .then(data => {
        let totalDeaths = 0;
        data.forEach(monthData => {
            totalDeaths += monthData.death_count;
        });
        const averageDeaths = totalDeaths / data.length;
        document.getElementById('averageDeaths').innerText = `Average deaths per month: ${averageDeaths}`;
    })
    .catch(error => console.error('Error fetching data:', error));
  }

  function fetchSumOfDeaths(year, month) {
    fetch(`http://localhost:8082/tbl_victims`)
    .then(response => response.json())
    .then(data => {
        const totalDeaths = data.totalDeaths || 0;
        document.getElementById('totalDeaths').innerText = `Total deaths in ${year}-${month}: ${totalDeaths}`;
    })
    .catch(error => console.error('Error fetching sum of deaths:', error));
  }


  return (
    <>
      <section id="kero" className="d-flex align-items-center">
        <div className="container">
                <div className="row">
                    <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                        <h1><strong>Kenya's Femicide Count Official Site</strong>
                            <p><strong><span>#StopKillingUs</span></strong></p>
                        </h1>
                        <h2>We count the reported number of Kenyan girls and women who have been killed through violence.</h2>

                        <div className="report-btn">
                            <a href="#report" className="report-case-btn">Report a Case</a>
                        </div>
                    </div>

                    <div className="col-lg-6 order-1 order-lg-2 hero-img aos-init aos-animate" data-aos="fade-left" data-aos-delay="200">
                        <img src={image66} className="img-fluid animated" alt="collage"/>
                    </div>
                </div>
            </div>
      </section>

      <section id="clients" className="clients">
        <div className="container">
                <div className="row counters">
                    <div className="col-lg-4 col-6 text-center">
                        <span data-purecounter-start="0" data-purecounter-end="209" data-purecounter-duration="1">0</span>
                        <p>2024 Death Count</p>
                    </div>

                    <div className="col-lg-4 col-6 text-center">
                        <span data-purecounter-start="0" data-purecounter-end="22" data-purecounter-duration="1">{fetchSumOfDeaths.length}</span>
                        <p>Total This Month</p>
                    </div>

                    <div className="col-lg-4 col-6 text-center">
                        <span data-purecounter-start="" data-purecounter-end="" data-purecounter-duration="">{averageDeaths.length}</span>
                        <p>Monthly Average</p>
                    </div>
                </div>
            </div>
      </section>

      <section id="portfolio" className="portfolio">
        <div className="container">
                <div className="section-title aos-init aos-animate" data-aos="fade-up">
                    <h2>Violence Against Women Cases</h2>
                    <p>Detailed cases of Victims who have succumbed to Femicide</p>
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 d-flex align-items stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <div className="porfolio-wrap">
                                <img src={image35} className="img-fluid" alt="..."/>
                                <div className="porfolio-info">
                                    <h4>Charity Wangui</h4>
                                    <p>View Story</p>
                                    <div className="porfolio-links">
                                        <a href="#links" data-gall="porfolioGallery" className="venobox vbox-item" title="App 1">
                                            <i className="bx bx-plus"><FaPlus/></i>
                                        </a>
                                        <a href="#links" data-gall="porfolioGalllery" className="venobox vbox-item" title="More Details">
                                            <i className="bx bx-link"><FaLink/></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex align-items stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <div className="porfolio-wrap">
                                <img src={image35} className="img-fluid" alt="..."/>
                                <div className="porfolio-info">
                                    <h4>Charity Wangui</h4>
                                    <p>View Story</p>
                                    <div className="porfolio-links">
                                        <a href="#links" data-gall="porfolioGallery" className="venobox vbox-item" title="App 1">
                                            <i className="bx bx-plus"><FaPlus/></i>
                                        </a>
                                        <a href="#links" data-gall="porfolioGalllery" className="venobox vbox-item" title="More Details">
                                            <i className="bx bx-link"><FaLink/></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex align-items stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                            <div className="porfolio-wrap">
                                <img src={image35} className="img-fluid" alt="..."/>
                                <div className="porfolio-info">
                                    <h4>Charity Wangui</h4>
                                    <p>View Story</p>
                                    <div className="porfolio-links">
                                        <a href="#links" data-gall="porfolioGallery" className="venobox vbox-item" title="App 1">
                                            <i className="bx bx-plus"><FaPlus/></i>
                                        </a>
                                        <a href="#link" data-gall="porfolioGalllery" className="venobox vbox-item" title="More Details">
                                            <i className="bx bx-link"><FaLink/></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
      </section>

      <section className="couts" id="couts">
        <div className="container">
            <div className="row">
                    <div className="image col-xl-5 d-flex justify-content-center justify-content-xl-start aos-init aos-animate" data-aos="fade-right" data-aos-delay="150">
                        <img src={image34} className="img-fluid" alt="..."/>
                    </div>

                    <div className="col-xl-7 d-flex pt-4 pt-xl-0 aos-init aos-animate" data-aos="fade-left" data-aos-delay="300">
                        <div className="content d-flex flex-column justify-content-center">
                            <div className="row">
                                <div className="col-md-6 d-md-flex align-items-md-stretch">
                                    <div className="count-box">
                                        <i className="iconfont-win-trophy"><FaTrophy/></i>
                                        <span data-toggle="counter-up">100</span>
                                        <p>
                                            <strong>Timely Updates&nbsp;</strong> 
                                            Find cataloged Information on Femicide
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 d-md-flex align-items-md-stretch">
                                    <div className="count-box">
                                        <i className="iconfont-blogger"><FaBlogger/></i>
                                        <span data-toggle="counter-up">24</span>
                                        <p>
                                            <strong>Our Blog&nbsp;</strong> 
                                            Engage with us through our blog posts in insightful topics around Femicide
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 d-md-flex align-items-md-stretch">
                                    <div className="count-box">
                                        <i className="iconfont-money"><FaMoneyBillWaveAlt/></i>
                                        <span data-toggle="counter-up">250</span>
                                        <p>
                                            <strong>Donate&nbsp;</strong> 
                                            Help raise awareness on Femicide
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 d-md-flex align-items-md-stretch">
                                    <div className="count-box">
                                        <i className="iconfont-listening"><FaAssistiveListeningSystems/></i>
                                        <span data-toggle="counter-up">15</span>
                                        <p>
                                            <strong>Articles&nbsp;</strong> 
                                            Access our increasing library on content with engaging topics around Femicide
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
      </section>

      <section id="events" className="events">
        <div className="container" data-aos="fade-up">
            <div className="row">
                    <div className="col-md-6 d-flex align-items-stretch">
                            <div className="board">
                                <div className="board-img">
                                    <img src={image38} className="img-fluid" alt="..."/>
                                </div>
                                <div className="board-body">
                                    <h5 className="board-title"><a href="#catalogue-cases">Catalogued Cases</a></h5>
                                    {/* <p className="fst-italic text-center">Tuesday, August 1st at 7:00am</p> */}
                                    <p className="board-text">Access this page and go through the populated list of cases so far.</p>
                                </div>
                            </div>
                    </div>

                    <div className="col-md-6 d-flex align-items-stretch">
                        <div className="board">
                                <div className="board-img">
                                    <img src={image58} className="img-fluid" alt="..."/>
                                </div>
                                <div className="board-body">
                                    <h5 className="board-title"><a href="#blog">Our Blogs</a></h5>
                                    {/* <p className="fst-italic text-center">Tuesday, August 1st at 7:00am</p> */}
                                    <p className="board-text">Get information from the interactions we have with diverse individuals on a number of topics.</p>
                                </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container" data-aos="fade-up">
                <div className="row">
                    <div className="col-md-6 d-flex align-items-stretch">
                        <div className="board">
                            <div className="board-img">
                                <img src={image40} className="img-fluid" alt="..."/>
                            </div>
                            <div className="board-body">
                                <h5 className="board-title"><a href="#articles">Articles</a></h5>
                                {/* <p className="fst-italic text-center">Tuesday, August 1st at 7:00 am</p> */}
                                <p className="board-text">Read content generated with topics related to Femicide that affect our society.</p>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
      </section>
    </>
  );
}

export default Catalogue;
