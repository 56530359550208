import React from "react";
import "./strategic.css";
import { FaCheckCircle } from "react-icons/fa";

function Strategic () {
    return (

        <>

        <section className="strategic-section">

            <div className="strategic-image-container">
                <h1><strong><span>STRATEGIC</span> PLAN</strong></h1>
            </div>

            <div className="strategic-texts"> 
                <h1>
                    <strong>Strategic Visions and Goals</strong>
                </h1>

                <p>
                    Femicide -Count Kenya started in January 2019, with the aim of increasing the visibility of femicide cases
                    from intimate partner violence. Femicide is a hate crime term broadly defined as “the intentional killing
                    of women or girls because they are female,” though definitions vary depending on its cultural
                    context. Over the four years, data analysis shows that Out of every three women who have died from
                    femicide the fourth femicide case will include a child. The majority of women who have died by femicide
                    are between the ages of 18-25 years, either in high school or University. However, there are other
                    reported femicide cases in older women up to the age of 75. Data shows that Femicide is more likely to
                    occur in cities or counties with larger towns in the country. For instance, there are higher numbers of
                    femicide cases reported in Nairobi County compared to other counties. In 95% of the reported cases, men
                    are the perpetrators of femicide.
                </p>

                <p>
                    <h4>Purpose</h4>
                        <i className="bx bx-check-circle"><FaCheckCircle/></i>
                        Femicide Count - Kenya is passionate about promoting human rights, gender equality, and safeguarding
                        in Kenya. Our primary focus is on the safety and well-being of women and children, through counting to
                        create an evidence base on femicide, hence triggering conversation on mitigation.
                </p>

                <p>
                    <h4>Vision</h4>
                        <i className="bx bx-check-circle"><FaCheckCircle/></i>
                        To create a society in Kenya where women and girls are free from violence and where their rights and
                        dignity are respected and protected.
                </p>

                <p>
                    <h4>Mission</h4>
                        <i className="bx bx-check-circle"><FaCheckCircle/></i>
                        To tackle the prevention of violence against women and children through research, capacity
                        strengthening, and safeguarding.
                </p>

                <p>
                    <h4>Values</h4>
                        <i className="bx bx-check-circle"><FaCheckCircle/></i>
                        Respect for human rights, Equality and justice, Empathy and compassion, Collaboration and partnerships,
                        Transparency and accountability, Professionalism and excellence, Innovation and creativity, Integrity and
                        ethics.
                </p>
            </div>
        </section>
        </>
    )
}

export default Strategic;