import "./footer.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";

function Footer() {

    const navigate = useNavigate();

    function handleLogin(){
        navigate('/login')
    }
    return (
      <>
        <footer id="footer">
          <div className="container d-md-flex py-4">
            <div className="me-md-auto text-center text-md-start">
              <div className="copyright">
                      &copy; Copyright <strong><span>Femicide Count-Kenya</span></strong>. All Rights Reserved
              </div>

              <div className="credits">
                 Designed and modified by <a href="#footer">Terah Mumuli</a>
              </div>
            </div>


            <div className="social-links text-center text-md-right pt-3 pt-md-0">
                    <button className="login-btn" formTarget="._blank" onClick={handleLogin}>Log In</button>
                    <a href="https://twitter.com/deadwomen_ke" target="_blank" rel="noreferrer" className="twitter"><i className="bx bxl-twitter"><FaTwitter/></i></a>
                    <a href="https://web.facebook.com/CountingDeadWomenKenya" target="_blank" rel="noreferrer"  className="facebook"><i className="bx bxl-facebook"><FaFacebook/></i></a>
                    <a href="https://www.instagram.com/femicide.ke/" target="_blank" rel="noreferrer" className="instagram"><i className="bx bxl-instagram"><FaInstagram/></i></a>
                    <a href="https://www.linkedin.com/in/femicidecount-kenya-457034235/" target="_blank" rel="noreferrer" className="linkedin"><i className="bx bxl-linkedin"><FaLinkedinIn/></i></a>
            </div>
          </div>
          </footer>
      </>

  )
}

export default Footer;