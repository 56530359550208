import "./backendform.css";
import React, { useState } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function BackendForm() {


   const [status, setStatus] = useState('loading');

  const [formData, setValue] = useState({
    name: "",
    occupation: "",
    age: "",
    county: "",
    month: "",
    year: "",
    death: "",
    perpetrator: "",
    justice: "",
    reason: "",
    media_outlet: "",
    story_link: "",
    summary: "",
    img_file: "",
    date_updated: "",
  });

  const [blogs, setBlogs] = useState({
    title: "",
    date_updated: "",
    name: "",
    img_file: "",
    story_link: "",
    summary: ""
  })

  const navigate = useNavigate();

  function handleBack() {
    navigate(-1);
  }

  async function handleIncident(e) {
    e.preventDefault();
    setStatus('loading');
    try {
      const response = await fetch('http://localhost:8082/tbl_victims', {
        method: "POST",
     
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      // console.log(data);
    } catch (error) {
      setStatus('error');
      console.error("Error:", error);
    }
  }

  async function handleBlogs(e) {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:8082/tbl_blogs', {
        method: "POST", 

        headers: {
          "Content-Type": "application/json", 
          Accept: "application/json", 
        }, 
        body: JSON.stringify(blogs),
      });

      const data = await response.json(); 
      // console.log(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  function handleChange(e) {
    setValue((previouState) => {
      return { ...previouState, [e.target.id]: e.target.value };
    });
  }

  function handleBlogsChange(e) {
    setBlogs((previouState) => {
      return { ...previouState, [e.target.id]: e.target.value }
    })
  }

  // console.log(formData);
  return (
    <>
      <section id="backend" className="backend">
        <button className="back-btn" onClick={handleBack}>
          <i className="bx bx-back">
            <FaAngleLeft />
          </i>
        </button>

        <div className="victim-form">
          <div className="section-title">
            <h4>Post Incident</h4>
          </div>
          <form
            action="forms/contact.php"
            method="post"
            className="php-email-form"
            onSubmit={handleIncident}
          >
            <div className="row">
                    <div className="col-md-6 form-group">
                      Victim's Name:
                      <input name="name" type="text" onChange={handleChange} className="form-control" id="name" placeholder="Name of Victim" required/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Victim's Occupation:
                      <input type="text" name="name" onChange={handleChange} className="form-control" id="occupation" placeholder="Victim's Occupation" required/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Victim's Age:
                      <input type="number" name="number" onChange={handleChange} className="form-control" id="age" placeholder="Age" required/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Victim's County:
                      <input type="text" className="form-control" onChange={handleChange} name="county" id="county" placeholder="Victim's County of Demise" required/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Month of Occurence:
                      <input type="text" name="month" onChange={handleChange} className="form-control" id="month" placeholder="Month" required/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Year of Occurence:
                      <input type="number" name="year" onChange={handleChange} className="form-control" id="year" placeholder="Year" required/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Death:
                      <input type="text" name="death" onChange={handleChange} className="form-control" id="death" placeholder="Death" required/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Perpetrator:
                      <input type="text" name="perpetrator" onChange={handleChange} className="form-control" id="perpetrator" placeholder="Perpetrator" required/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Justice:
                      <input type="text" name="justice" onChange={handleChange} className="form-control" id="justice" placeholder="Justice"/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Reason: 
                      <input type="text" name="reason" onChange={handleChange} className="form-control" id="reason" placeholder="Reason for Killing"/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Date Updated:
                      <input type="date-time" name="date-time" onChange={handleChange} className="form-control" id="date_updated" placeholder="date-time-updated"/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Media Outlet:
                      <input type="url" name="url" onChange={handleChange} className="form-control" id="media_outlet" placeholder="Media Url" required/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Story Url:
                      <input type="url" name="url" onChange={handleChange} className="form-control" id="story_link" placeholder="Story Url"/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Image:
                      <input type="url" name="image" onChange={handleChange} className="form-control" id="img_file" placeholder="Image Url" alt="..."/>
                    </div>
                    <div className="form-group mt-3 mt-md-0">
                      Summary of the Occurence:
                      <textarea className="form-control" name="message" onChange={handleChange} rows="7" id="summary" placeholder="Summary" required></textarea>
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">Your case has been sent. Thank you!❤️❤️</div>
                    </div>
                    <div className="text-center">
                      <button type="submit">Submit</button>
                    </div>
                  </div>
          </form>
        </div>
      </section>

      <section id="backend" className="backend">
        <div className="victim-form">
          <div className="section-title">
            <h4>Post a Blog</h4>
          </div>
          <form action="forms/contact.php" method="post" className="php-email-form" onSubmit={handleBlogs}>
                  <div className="row" id="blogList">
                    <div className="col-md-6 form-group">
                      Blog title:
                      <input name="title" type="text" className="form-control" onChange={handleBlogsChange} id="title" placeholder="Blog title" required/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Date:
                      <input type="date" className="form-control" onChange={handleBlogsChange} name="name" id="date_updated" placeholder="mm/dd/yyy" required/>
                    </div>
                    <div className="col-md-6 form-group">
                      Author:
                      <input type="name" name="name" className="form-control" onChange={handleBlogsChange} id="name" placeholder="author" required/>
                    </div>
                    <div className="col-md-6 form-group">
                      Image:
                      <input type="url" name="image" className="form-control" onChange={handleBlogsChange} id="img_file" placeholder="Image Url" alt="..."/>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      Story Url:
                    <input type="url" name="url" className="form-control" onChange={handleBlogsChange} id="story_link" placeholder="Story Url"/>
                    </div>
                    <div className="form-group mt-3">
                      About blog:
                      <textarea className="form-control" onChange={handleBlogsChange} name="message" rows="10" id="summary" placeholder="Share blog" required></textarea>
                    </div>
                    <div className="my-3">
                      <div className="loading">Loading</div>
                      <div className="error-message"></div>
                      <div className="sent-message">Your blog has been posted. Thank you!😃</div>
                    </div>
                    <div className="text-center"><button type="submit">Post Blog</button></div>
                  </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default BackendForm;