import React from "react";
import "./mission.css";
import image49 from "../../images/women 49 .jpg";

function Mission () {
    return (
        <>
                <section className="mission-section">
                    <div className="mission-image-container aos-init aos-animate">
                        <h1><strong>MISSION & <span>VISION</span></strong></h1>
                    </div>
                </section>

                <section id="about" className="about">
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-xl-0 col-lg-6 video-box d-flex justify-content-center align-items-stretch position-relative aos-init aos-animate" data-aos="fade-right" data-aos-delay="200">
                                    <img src={image49} className="img-fluid" alt="..."/>
                                </div>
                    
                                <div className="col-xl-5 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                                    <h3><strong>Vision Statement</strong></h3>
                                    <p>We envision a Kenya where all individuals, regardless of gender, have equal access to education, healthcare, and the protection of their human rights.</p>


                                    <h3><strong>Mission Statement</strong></h3>
                                    <p>Our mission is to conduct research, build capacity, and provide funding for the education of underprivileged children in Kenya, while also advocating for the protection of human rights and the elimination of femicide.</p>
                                </div>
                            </div>
                        </div>
            </section>
        </>
    )
}

export default Mission;