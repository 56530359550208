import "./blog.css";
import { useNavigate } from "react-router-dom";
import image42 from "../../images/women 42 .jpg";
import image41 from "../../images/women 41 .jpg";
import image43 from "../../images/women 43 .jpg";
import React, { useEffect, useState } from "react";
import { FaRegClock, FaRegCommentDots, FaRegUser } from "react-icons/fa";

function Blog() {
  const navigate = useNavigate();

  function handleReadMore() {
    navigate('/singlepost');
  }

  function handleReadMore1() {
    navigate('/singlepost1');
  }

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          "https://www.womenforwomen.org/blogs/end-violence-against-women-means-zero-femicides"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const resultData = await response.json();
        setData(resultData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    }

    fetchData();
  }, []);



    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
      async function fetchBlogs() {
        try {
          const response = await fetch('http://localhost:8082/tbl_blogs');
          const blogData = await response.json(); 
          setBlogs(blogData);
        } catch (error) {
          console.error('Error fetching blogs:', error);
        }
      }

      fetchBlogs();
    }, []);

  // async function fetchBlogs() {
  //   try {
  //   const response = await fetch('http://localhost:8082/tbl_blogs');
  //   const blogs = await response.json();

  //     const blogList = document.getElementById('blogList');
  //     blogs.forEach(blog => {
  //       const blogElement = document.createElement('div');
  //       blogElement.innerHTML = `
  //       <h2>${blog.title}</h2>
  //       <p><strong>Date Updated</strong> ${blog.date_updated}</p>
  //       <p><strong>Name:</strong> ${blog.name}</p>
  //       <img src="${blog.img_file}" alt="${blog.title}" style="max-width: 100%; height: auto;">
  //       <p><strong>Story Link:</strong> <a href="${blog.story_link} target="_blank">${blog.title}</a></p>
  //       <p><strong>Summary:</strong> ${blog.summary}</p>
  //       <hr>
  //       `;
  //       blogList.appendChild(blogElement);
  //     });
  //   } catch (error) {
  //     console.error('Error fetching blogs:', error);
  //   }
  // }

  // window.onload = fetchBlogs;

  return (
    <>
      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-8 entries">
              {blogs.map((blog, index) => (
              <article className="entry" key={index}>
                <div className="entry-img">
                  <img src={blog.img_file} alt={blog.title} className="img-fluid" />
                </div>

                <h2 className="entry-title">
                  <a href="#story">{blog.title}</a>
                </h2>

                <div className="entry-meta">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person">
                        <FaRegUser />
                      </i>
                      <a href="singlepost1">{blog.name}</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock">
                        <FaRegClock />
                      </i>
                      <a href="singlepost1">
                        <time dateTime={blog.date_updated}>{blog.date_updated}</time>
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-chat-dots">
                        <FaRegCommentDots />
                      </i>
                      <a href="singlepost1">Comments</a>
                    </li>
                  </ul>
                </div>

                <div className="entry-content">
                  <p>{blog.summary}</p>
                </div>

                <div className="read-more">
                  <a href="#story" onClick={() => handleReadMore(blog.story_link)}>Read More</a>
                </div>
            </article>
              ))}
              <article className="entry">
                <div className="entry-img">
                  <img src={image41} alt="impact" className="img-fluid" />
                </div>

                <h2 className="entry-title">
                  <a href="#story">THE IMPACT OF FEMICIDE ON THE COMMUNITY</a>
                </h2>

                <div className="entry-meta">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person">
                        <FaRegUser />
                      </i>
                      <a href="singlepost1">Angela Langat</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock">
                        <FaRegClock />
                      </i>
                      <a href="singlepost1">
                        <time dateTime="2023-06-12">Jun 12, 2023</time>
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-chat-dots">
                        <FaRegCommentDots />
                      </i>
                      <a href="singlepost1">Comments</a>
                    </li>
                  </ul>
                </div>

                <div className="entry-content">
                  <p>
                    Femicide shatters families and communities. Femicide not
                    only robs women of their lives but also inflicts
                    immeasurable pain and trauma on their families and loved
                    ones. The loss of a mother, daughter, sister, or friend
                    creates a void that can never be truly filled. It leaves
                    grieving families to navigate the complexities of loss,
                    grief, and injustice. The ripple effects are profound,
                    causing emotional, psychological, and financial hardships
                    that may persist for generations. Femicide tears apart the
                    very fabric of families, leaving scars that are often
                    impossible to heal.
                  </p>
                </div>

                <div className="read-more">
                  <a href="#story" onClick={handleReadMore1}>
                    Read More
                  </a>
                </div>
              </article>
            </div>
          </div>
        </div>

        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-8 entries">
              <article className="entry">
                <div className="entry-img">
                  <img src={image43} alt="women" className="img-fluid" />
                </div>

                <h2 className="entry-title">
                  <a
                    href="https://www.womenforwomen.org/blogs/end-violence-against-women-means-zero-femicides"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    AN END TO VIOLENCE AGAINST WOMEN MEANS ZERO FEMICIDES
                  </a>
                </h2>

                <div className="entry-meta">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person">
                        <FaRegUser />
                      </i>
                      <a
                        href="https://www.womenforwomen.org/blogs/end-violence-against-women-means-zero-femicides"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Audrey Mugeni
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock">
                        <FaRegClock />
                      </i>
                      <a
                        href="https://www.womenforwomen.org/blogs/end-violence-against-women-means-zero-femicides"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <time dateTime="2022-04-19">Apr 19, 2022</time>
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-chat-dots">
                        <FaRegCommentDots />
                      </i>
                      <a
                        href="https://www.womenforwomen.org/blogs/end-violence-against-women-means-zero-femicides"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Comments
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="entry-content">
                  <p>
                    According to the United Nations, 137 women and girls are
                    murdered on average a day by their partner or someone very
                    close to them. Ending violence against women means that the
                    international community must prioritize ending femicides
                    across the world.
                  </p>
                </div>

                <div className="read-more">
                  <a
                    href="https://www.womenforwomen.org/blogs/end-violence-against-women-means-zero-femicides"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read More
                  </a>
                </div>
              </article>
            </div>
          </div>
        </div>

        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-8 entries">
              <article className="entry">
                <div className="entry-img">
                  <img src={image42} alt="young-girls" className="img-fluid" />
                </div>

                <h2 className="entry-title">
                  <a href="#story">TO BE A TEENAGER</a>
                </h2>

                <div className="entry-meta">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person">
                        <FaRegUser />
                      </i>
                      <a href="singlepost">Audrey Mugeni</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock">
                        <FaRegClock />
                      </i>
                      <a href="singlepost">
                        <time dateTime="2023-01-0">Jan 1, 2020</time>
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-chat-dots">
                        <FaRegCommentDots />
                      </i>
                      <a href="singlepost">Comments</a>
                    </li>
                  </ul>
                </div>

                <div className="entry-content">
                  <p>
                    Most of us know that period when you are too old to be with
                    the children and too young to be with adults. That period
                    is commonly known as teenage hood. What is it about?
                    Especially here in Kenya.
                  </p>
                  <div className="read-more">
                    <a href="#singlepost" onClick={handleReadMore}>
                      Read More
                    </a>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;