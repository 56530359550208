import React from "react";
import "./research.css";
import image50 from "../../images/women 50 .jpg";
import image62 from "../../images/women 62 .jpg";

function Research() {
    return (
        <>

        <section className="research-section">

            <div className="research-image-container">
                <h1><strong><span>RESEARCH</span></strong></h1>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-6 order-1 order-lg-1 hero-img aos-init aos-animate" data-aos="fade-right" data-aos-delay="200" style={{marginTop: "65px"}}>
                        <img src={image62} className="img-fluid" alt="..."/>
                    </div>

                    <div className="col-lg-6 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100" style={{marginTop: "65px"}}>
                        <p>
                            At Femicide Count Kenya we believe that research is the back bone of our work. We believe
                            that numbers do not lie and numbers come from informed research. If you look at the history of
                            femicide count you will see that thiss all started with the collection of data and the realisation
                            that little to know research had been conducted on femicides in Kenya or on intimate partner
                            violence. This is the gap that FC-Kenya would like to fill.
                        </p>

                        <p>
                            Our research strategy focuses on the voices of the community listening to and speaking to the
                            experiences of the Kenyan/African Woman and the Kenyan/African Family. The research will
                            borrow heavily on the data available and in curating data where data lacks.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-6 pt-lg-0 order-1 order-lg-1 d-flex flex-column justify-content-center aos-init aos-animate" data-aos="zoom-in" data-aos-delay="100">
                        <p>
                            Through our partnerships, we aim to amplify our impact, leverage our resources, and achieve
                            greater scale and sustainability in our programs and advocacy efforts. We collaborate with
                            partners on a range of initiatives, from research and capacity-building programs to education
                            support and advocacy campaigns.
                            We also recognize that partnerships require ongoing investment and attention. That&#39;s why we
                            prioritize open and transparent communication, regular monitoring and evaluation, and mutual
                            accountability in all our partnerships. We believe that these elements are critical to building
                            strong and lasting partnerships that can create meaningful change.
                        </p>

                        <p>
                            If you&#39;re interested in partnering with us, we&#39;d love to hear from you. Please reach out to us
                            through our website or email, and let&#39;s work together towards a more just and equitable Kenya.
                        </p>
                    </div>

                    <div className="col-lg-6 order-2 order-lg-2 hero-img aos-init aos-animate" data-aos="fade-left" data-aos-delay="200">
                        <img src={image50} className="img-fluid" alt="..."/>
                    </div>
                </div>
            </div>
        </section>
        </>
    )
}

export default Research;