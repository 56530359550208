import React from "react";
import "./count.css";


function Count (){
    return(

        <>
            <section id="contact" className="contact section-bg">
                <div className="container">
                    <div className="section-title">
                    <h2><strong>Count with us</strong></h2>
                    </div>

                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-10">
                            <form action="forms/contact.php" method="post" className="php-email-form">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        Victim's Name:
                                        <input type="text" name="name" className="form-control" id="name" placeholder="Victim's Name" required/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        Victim's Age:
                                        <input type="number" name="number" className="form-control" id="number" placeholder="Victim's Age" required/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        Date of occurence:
                                        <input type="date" name="name" className="form-control" id="name" placeholder="mm/dd/yyyy" required/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        Your email:
                                        <input type="email" name="email" className="form-control" id="email" placeholder="Your Email" required/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        Victim's occupation:
                                        <input type="text" name="name" className="form-control" id="name" placeholder="Victim's Occupation" required/>
                                    </div>
                                    <div className="col-md-6 form-group mt-3 mt-md-0">
                                        Victim's County of demise:
                                        <input type="text" className="form-control" name="county" id="county" placeholder="Victim's County of Demise" required/>
                                    </div>
                                </div>
                                <div className="form-group mt-3">
                                    A brief description of the occurence:
                                    <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                                </div>
                                <div className="my-3">
                                        <div className="loading">Loading</div>
                                        <div className="error-message"></div>
                                        <div className="sent-message">Your message has been sent. Thank you!❤️❤️</div>
                                </div>
                                <div><button className="report-case-btn" type="submit">Report Case</button></div>
                            </form>
                        </div>
                    </div>
                    
                </div>
                <a href="#back-to-top" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
            </section>
        </>        
    )
}

export default Count;