import "./volunteers.css";
import React, { useState } from "react";
import { FaLocationArrow, FaPhoneAlt, FaRegEnvelope } from "react-icons/fa";

function Volunteers() {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    location: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      // Simulate an asynchronous form submission
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Reset form fields
      setFormData({
        name: "",
        number: "",
        email: "",
        location: "",
        message: "",
      });

      // Display success message
      setSuccess(true);
    } catch (err) {
      setError("An error occurred while submitting the form.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section id="contact" className="contact">
        <div className="container">
          <div className="row mt-5">
            <div className="col-lg-4">
              <div className="info">
                <div className="address">
                  <i className="bi bi-geo-alt">
                    <FaLocationArrow />
                  </i>
                  <h4>Location:</h4>
                  <p>G.P.O, Kenyatta Avenue, Nairobi Kenya, NBO 30805-00100</p>
                </div>

                <div className="email">
                  <i className="bi bi-envelope">
                    <FaRegEnvelope />
                  </i>
                  <h4>Email:</h4>
                  <p>femicide@femicidecount.org</p>
                </div>

                <div className="phone">
                  <i className="bi bi-phone">
                    <FaPhoneAlt />
                  </i>
                  <h4>Call:</h4>
                  <p>+254785108805</p>
                </div>
              </div>
            </div>

            <div className="col-lg-8 mt-5 mt-lg-0">
              {success ? (
                <div className="sent-message">
                  Your message has been sent. Thank you!
                </div>
              ) : (
                <form onSubmit={handleSubmit} className="php-email-form">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="number"
                        name="number"
                        value={formData.number}
                        onChange={handleInputChange}
                        className="form-control"
                        id="number"
                        placeholder="Your Number"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                        className="form-control"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="location"
                        value={formData.location}
                        onChange={handleInputChange}
                        className="form-control"
                        id="location"
                        placeholder="Location"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                      rows="5"
                      placeholder="In 100 words tell us why you would like to volunteer with us"
                      maxLength={150}
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    {loading && <div className="loading">Loading</div>}
                    {error && (
                      <div className="error-message">{error}</div>
                    )}
                  </div>
                  <div className="text-center">
                    <button type="submit" disabled={loading}>
                      {loading ? "Sending..." : "Send Message"}
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Volunteers;
