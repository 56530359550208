import "./home.css";
import React from "react";
import image3 from "../../images/women 3.jpg";
import image6 from "../../images/women 6.jpg";
import { useNavigate } from "react-router-dom";
import image10 from "../../images/women 10.jpg";
import image12 from "../../images/women 12.jpg";
import image14 from "../../images/women 14.jpg";
import image23 from "../../images/women 23 .jpg";
import image64 from "../../images/women 64 .jpg";

function Home () {

    const navigate = useNavigate();

    function handleDonate() {
        navigate('/donate')
    }

    function handleSafeguarding () {
        navigate('/safeguarding')
    }

    function handleResearch () {
        navigate('/research')
    }

    function handleCapacityBuilding () {
        navigate('/capacity-building')
    }

    function handleStrategicPlans () {
        navigate('/strategic-plan')
    }

    function handleCatalogue () {
        navigate('/catalogued-cases')
    }

    function handleBlogs () {
        navigate('/blog')
    }

    // function handleLegal () {
    //     navigate('/legal-framework')
    // }
    return(
        <>
                <section id="hero" className="d-flex flex-column justify-content-center align-items-center">
                    <div className=" text-center text-md-left">
                        <button className="donate-btn"onClick={handleDonate}>Donate</button>
                    </div>
                </section>

                <section id="what">
                    <div className="container mt-2">
                        <h2><strong>WHAT WE DO</strong></h2>

                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 d-flex align-items stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                    <div className="card card-block">
                                        <div className="child-img-1">
                                            <img src={image12} alt="..." className="img-fluid"/>
                                        </div>

                                        <div className="child-text">
                                            <h5 onClick={handleSafeguarding}><strong>SAFEGUARDING</strong></h5>
                                            <p>Developing and implementing programs that provide funding and support for the education of underprivileged children, with a focus on girls and marginalized communities.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 d-flex align-items stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                    <div className="card card-block">
                                        <div className="child-img-1">
                                            <img src={image6} alt="..." className="img-fluid"/>
                                        </div>

                                        <div className="child-text">
                                            <h5 onClick={handleResearch}><strong>RESEARCH</strong></h5>
                                            <p>Conducting research on femicide, gender inequality and the impact of education on community develoment in Kenya, and disseminating findings to relevant stakeholders.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-6 d-flex align-items stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                    <div className="card card-block">
                                        <div className="child-img-1">
                                            <img src={image64} alt="..." className="img-fluid"/>
                                        </div>

                                        <div className="child-text">
                                            <h5 onClick={handleCapacityBuilding}><strong>CAPACITY BUILDING</strong></h5>
                                            <p>Providing capacuty building support to individuals and organizations working towards promoting human rights and education in Kenya.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="resources-section">
                        <div className="container aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <h2><strong>RESOURCES</strong></h2>
                            <div className="parent">
                                <div className="child">
                                        <div className="child-img">
                                            <img src={image10} alt="..."/>
                                        </div>
                                        <div className="child-text">
                                            <a href="#strategic-plans" onClick={handleStrategicPlans}><h5>STATEGIC PLANS 2023-2027</h5></a>
                                            <a href="#strategic-plans"><p>We stand guided by this roadmap in our drive to advance human rights.</p></a>
                                        </div>
                                </div>
                                <div className="child">
                                    <div className="child-img">
                                        <img src={image3} alt="..."/>
                                    </div>
                                    <div className="child-text">
                                        <a href="#catalogue-cases" onClick={handleCatalogue}><h5>CATALOGUED CASES</h5></a>
                                        <a href="#catalogue-cases"><p>It all began with counting dead women! Their deaths were not in vain. Make them count!</p></a>
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="child-img">
                                        <img src={image14} alt="..."/>
                                    </div>
                                    <div className="child-text">
                                        <a href="#blogs" onClick={handleBlogs}><h5>BLOG/ARTICLE</h5></a>
                                        <a href="#blogs"><p>News write-ups , scholarly articles, infographics, opinion pieces and more!</p></a>
                                    </div>
                                </div>
                                <div className="child">
                                    <div className="child-img">
                                        <img src={image23} alt="..."/>
                                    </div>
                                    <div className="child-text">
                                        <a href="#legal-framework"><h5>LEGAL FRAMEWORK</h5></a>
                                        <a href="#legal-framework"><p>We advocate for laws and policies that protect and promote human rights.</p></a>
                                    </div>
                                </div>
                            </div> 
                        </div>
                </section>
        </>
    )
}

export default Home;