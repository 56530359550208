import React from "react";
import "./singlepost1.css";
import { FaAngleLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import image41 from "../../images/women 41 .jpg";

function SinglePost1() {
  const navigate = useNavigate();

  function handleBack() {
    navigate(-1);
  }

  return (
    <>
      <section id="blog" className="blog">
        <button href="#back" className="back-btn" onClick={handleBack}>
          <i className="bx bx-back">
            <FaAngleLeft />
          </i>
        </button>
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-8 entries">
              <article className="entry entry-single">
                <div className="entry-img">
                  <img src={image41} alt="young-girls" className="img-fluid" />
                </div>

                <h2 className="entry-title">
                  <a href="#title">IMPACT OF FEMICIDE ON THE COMMUNITY</a>
                </h2>

                <div className="entry-meta">
                  <ul>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-person"></i>
                      <a href="#author">Audrey Mugeni</a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-clock"></i>
                      <a href="#time">
                        <time dateTime="2023-01-01">Jan 1, 2020</time>
                      </a>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="bi bi-chat-dots"></i>
                      <a href="#comments">Comments</a>
                    </li>
                  </ul>
                </div>

                <div className="entry-content">
                        <p>
                            Femicide shatters families and communities. Femicide not only robs women of their lives but also inflicts immeasurable pain and trauma on their families and loved ones. The loss of a mother, daughter, sister, or friend creates a void that can never be truly filled. It leaves grieving families to navigate the complexities of loss, grief, and injustice. The ripple effects are profound, causing emotional, psychological, and financial hardships that may persist for generations. Femicide tears apart the very fabric of families, leaving scars that are often impossible to heal.
                        </p>

                        <p>
                            Femicide creates an atmosphere of fear that permeates communities. When women are targeted solely because of their gender, a climate of terror takes hold, impacting the lives of all women. Fear acts as a powerful deterrent, preventing women from fully participating in public life, expressing themselves freely, and asserting their rights. The constant threat of violence limits their choices and curtails their aspirations. After a spate of gang-related killings and sexual assaults of women in Mawanga in June 2022, women reported living in fear. They were forced them to adopt self-protective measures that restricted their freedom such as staying outside in groups rather than being alone in one’s house. Business people also closed their businesses early. Some residents even moved out of the area to avoid becoming a victim.
                        </p>

                        <p>
                            Femicide has significant economic repercussions for communities. When women are targeted and killed, it disrupts their economic participation, resulting in a loss of productive workforce. This loss affects not only individual families but also the community's economic growth and development. Furthermore, the financial burden of funeral costs, legal proceedings, and support services for affected families can strain community resources. When Mary Lawrence’s sister Juliana Wanza was murdered by her boyfriend, Mary was forced to abandon her dream of university education which her sister had been paying for. Additionally, she had to find employment to care for her sister’s 3 children. 
                        </p>

                        <p>
                            Finally, femicide reinforces gender inequality. Femicide is not an isolated act; it is deeply rooted in gender inequality and discriminatory attitudes. By perpetuating a cycle of violence against women, femicide reinforces harmful gender stereotypes and norms. It sends a chilling message to women and girls, suggesting that their lives are dispensable and that they are inherently inferior. This reinforces a culture of male entitlement and domination, which further perpetuates gender-based violence. This is further reinforced by the fact that justice is often elusive for families. For example, it took almost 4 years for Elizabeth Wairimu Gatimu to be arraigned in court for the murder of Ebbie Noelle.
                        </p>

                        <p>
                            Femicide has far-reaching consequences. Therefore, collective action is crucial to end these needless deaths. Governments, civil society organizations, community leaders, and individuals must unite to address the root causes of femicide and work towards building safe, equitable, and inclusive communities.
                        </p>
                      </div>
              </article>

              <div className="reply-form">
                <h4>Leave a reply</h4>
                <p>Your email address will not be published. Required fields are marked *</p>
                <form action="">
                        <div className="row">
                          <div className="col-md-6 form-group">
                            <input name="name" type="text" className="form-control" placeholder="Your Name*" required/>
                          </div>
                          <div className="col-md-6 form-group">
                            <input name="email" type="text" className="form-control" placeholder="Your Email*" required/>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col form-group">
                            <input name="website" type="text" className="form-control" placeholder="Your Website"/>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col form-group">
                            <textarea name="comment" className="form-control" placeholder="Your Comment*"></textarea>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-primary">Post Comment</button>
                      </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SinglePost1;