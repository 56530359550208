import React from "react";
import "./advocacy.css"


function Advocacy () {
    return (
        <>

            <section className="advocacy-section">
                <div className="advocacy-image-container">
                    <h1><strong>ADVOCACY <span>REPORTS</span></strong></h1>
                </div>

                <div className="advocacy-texts">
                    <h1>
                        <strong>CAMPAIGNING FOR AND WITH WOMEN<span></span></strong>
                    </h1>

                    <p>
                        Our advocacy strategy aims to promote gender equality and eliminate femicide in Kenya by
                        using evidence-based research to inform our advocacy efforts.
                    </p>

                    <p>
                        We will work to engage and educate the public, government officials, and other key
                        stakeholders through various advocacy methods such as media campaigns, public education
                        events, and lobbying efforts.
                    </p>

                    <p>
                        We will also collaborate with other organizations and stakeholders to increase our reach and
                        effectiveness in advocating for policies and programs that promote the protection of human
                        rights, especially for women and children.
                    </p>

                    <p>
                        Through these efforts, we hope to improve the lives of marginalized communities and create a
                        more just and equitable society in Kenya. We believe that our advocacy efforts will contribute to
                        positive change, as we continue to base our work on evidence and collaborate with peers to
                        ensure that our message is heard.
                    </p>
                </div>
            </section>
        </>
    )
}

export default Advocacy;